import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/cmsPages/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Dev Nybbles are extremely short, `}<MDXTag name="em" components={components} parentName="p">{`mostly`}</MDXTag>{` correct explanations of tech concepts.`}</MDXTag>
      <MDXTag name="p" components={components}>{`They strive to give you a general overview so you can understand the terms in the right context.`}</MDXTag>
      <MDXTag name="p" components={components}>{`They are not an exhaustive or pedantically accurate summary.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      